import { h } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Footer from './footer';

const App = () => (
	<div id="app">
		<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap" />
		<Router>
			<Home path="/" />
		</Router>
		<Footer />
	</div>
)

export default App;
